<template>
  <default-layout>
    <v-container fluid>
      <v-card>
        <v-toolbar color="secondary" flat>
          <v-toolbar-title>Recurrent Transactions Templates</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="accent" small to="/trading/new-recurrent-template">
            <v-icon left dark>mdi-plus</v-icon>New Template
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field v-model="search" append-icon="mdi-search" label="Search" single-line></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-data-table
                :loading="loading"
                loading-text="Loading... Please wait"
                :headers="headers"
                :items="templatesData"
                :search="search"
              ></v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
  </default-layout>
</template>

<script>
export default {
  data: () => ({
    search: null,
    templatesData: [],
    headers: [
      { text: "Template", value: "Code" },
      { text: "Description", value: "Dscription" },
      { text: "Type", value: "DocObjType" },
      { text: "Doc No.", value: "DraftEntry" },
      { text: "Recurrence Date", value: "Remind" },
      { text: "Recurrence Period", value: "Frequency" },
      { text: "Start Date", value: "StartDate" },
      { text: "Next Execution", value: "" },
      { text: "Valid Until", value: "EndDate" }
    ]
  }),
  methods: {
    getTemplates() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/recurringtransactiontemplates`)
        .then(res => {
          self.templatesData = res;
          self.loading = false;
        })
        .catch(err => {
          this.$refs.snackbar.show(err, "red");
        });
    }
  },
  created() {
   this.getTemplates();
  }
};
</script>